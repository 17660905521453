/* this file is transformed by vux-loader */
/* eslint-disable */
import _toConsumableArray from "F:/zhaoyanning/\u9ED1\u9F99\u6C5F\u533B\u79D1\u5927\u5B66\u9644\u5C5E\u533B\u9662/mobile-v2/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import ViewBox from 'vux/src/components/view-box';
import XButton from 'vux/src/components/x-button';
import Group from 'vux/src/components/group';
import Cell from 'vux/src/components/cell';
import XInput from 'vux/src/components/x-input';
import Scroll from '@/components/Scroll/Scroll';
export default {
  name: 'AgentList',
  components: {
    ViewBox: ViewBox,
    XButton: XButton,
    Group: Group,
    Cell: Cell,
    Scroll: Scroll,
    XInput: XInput
  },
  data: function data() {
    return {
      infoList: [],
      pullDownRefresh: {
        threshold: 40,
        // 下拉多少开始刷新
        stop: 20 // 下拉回弹到多少停住

      },
      pullUpLoad: {
        threshold: 25
      },
      isPullDown: false,
      // 正在下拉刷新
      isPullUp: false,
      // 正在上拉加载
      pageIndex: 1,
      pageSize: 20,
      totalPage: 0,
      emptyShow: false,
      search: '',
      userType: ''
    };
  },
  computed: {},
  methods: {
    onPullingDown: function onPullingDown() {
      if (this.isPullDown) return;
      this.isPullDown = true; // 下拉刷新

      this.infoList = [];
      this.pageIndex = 1;
      this.getInfoList();
    },
    onPullingUp: function onPullingUp() {
      this.pageIndex = this.pageIndex + 1;

      if (this.pageIndex > this.totalPage) {
        this.updateScroll();
        return;
      }

      if (this.isPullUp) return;
      this.isPullUp = true;
      this.getInfoList();
    },
    updateScroll: function updateScroll(flag) {
      this.isPullDown = false;
      this.isPullUp = false;
      this.$refs.scroll.forceUpdate(flag);
    },
    clearSearch: function clearSearch() {
      this.search = '';
      this.getInfoList();
    },
    getInfoList: function getInfoList() {
      var _this = this;

      this.$utils.Tools.loadShow();
      this.emptyShow = false;
      var params = {
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
        search: {
          userSerial: this.userType === '0' ? this.$utils.Store.getItem('userSerial') : null,
          keys: this.search
        }
      };
      this.$api.Agent.selectListPageByEntity(params).then(function (response) {
        _this.$utils.Tools.loadHide();

        var code = response.code,
            msg = response.msg,
            data = response.data;

        if (+code !== _this.$code.success) {
          _this.$utils.Tools.toastShow(msg);

          _this.updateScroll(false);
        } else {
          var _this$infoList;

          (_this$infoList = _this.infoList).push.apply(_this$infoList, _toConsumableArray(data.records));

          _this.pageIndex = data.pageIndex;
          _this.totalPage = data.totalPage;

          if (_this.infoList.length === 0) {
            _this.emptyShow = true;
          }

          _this.updateScroll(false);
        }
      }).catch(function () {
        _this.$utils.Tools.httpError();
      });
    },
    getUserInfo: function getUserInfo() {
      var _this2 = this;

      this.$api.User.getUserInfo(this.$utils.Store.getItem('userSerial')).then(function (response) {
        var code = response.code,
            msg = response.msg,
            data = response.data;

        if (+code !== _this2.$code.success) {
          _this2.$utils.Tools.toastShow(msg);
        } else {
          if (data && data.lx === '99') {
            _this2.userType = '0';
          } else {
            _this2.userType = '1';
          }
        }
      }).catch(function () {
        _this2.$utils.Tools.httpError();
      });
    },
    recordClick: function recordClick(value, operation) {
      this.$router.push({
        path: '/AgentForm',
        query: {
          xh: value,
          operation: operation
        }
      });
    },
    submitData: function submitData() {
      this.$router.push({
        path: '/AgentForm',
        query: {
          operation: 'add'
        }
      });
    }
  },
  created: function created() {
    this.getUserInfo();
    this.getInfoList();
  },
  mounted: function mounted() {},
  destroyed: function destroyed() {}
};